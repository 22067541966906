<template>
  <div class="notification common-page">
    <div v-if="componentName == 'default'">
      <header class="page-header">
        <div class="header-left">
          <div class="title-instruction"></div>
          <span class="header-title">违章概况</span>
        </div>
        <div class="header-right"></div>
      </header>
      <section class="page-content">
        <div class="search-wrapper">
          <div class="search-title">违章筛选</div>
          <Divider class="divider-wrapper" size="small" />
          <div class="search-content">
            <div class="search-item">
              <RadioText @changeRadio="alarmTypeChange" :radio="alarmType" :defaultRadio="searchForm.alarmType" title="违章统计:" labelWidth="72"></RadioText>
            </div>
            <div class="search-item">
              <RadioText @changeRadio="idStatusChange" :radio="idStatusData" :defaultRadio="searchForm.status" title="状态:" labelWidth="72"></RadioText>
            </div>
            <div class="search-item">
              <Form :model="searchForm" :label-width="0" inline>
                <FormItem label="企业名称:" :label-width="72">
                  <AutoComplete v-model="searchForm.unitName" @on-focus="unitSearch" @on-search="unitSearch" @on-select="unitSelect" @on-clear="clearAutoComplete" :clearable="true" placeholder="请选择企业" style="width:200px">
                    <Option v-for="item in unitArray" :value="`${item.unitName}|${item.unitId}`" :key="item.unitId">{{ item.unitName }}</Option>
                  </AutoComplete>
                </FormItem>
                <FormItem label="违章时间" :label-width="68">
                  <!-- <el-date-picker
                    v-model="searchForm.time"
                    type="week"
                    format="yyyy 第 WW 周"
                    placeholder="选择周"
                    :picker-options="{ firstDayOfWeek: 1 }"
                    :clearable="false"></el-date-picker> -->
                     <Date-picker v-model="searchForm.time" format="yyyy/MM/dd" type="daterange" placeholder="选择日期" style="width: 220px;"></Date-picker>
                </FormItem>
                <FormItem>
                  <Input v-model="searchForm.vehicleNo" placeholder="车牌号/ID号 快速查询"/>
                </FormItem>
                <FormItem>
                  <Button v-if="btnPrivilegeCode.illegal.includes('read')" @click="getTotalData('search')" :loading="searchLoading" class="form-btn" type="primary">查询</Button>
                  <!-- <Button v-if="btnPrivilegeCode.illegal.includes('create')" @click="addComplaintOrGovern" class="form-btn" type="primary" icon="md-add">添加申诉/治理</Button> -->
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <Spin size="large" fix v-if="searchLoading"></Spin>
          <Table :columns="tableColmuns" :data="tableData" size="small">
            <template slot-scope="{ row }" slot="vehicle">
              {{`${row.vehicleNo}(${row.vehiclePlateColor})`}}
            </template>
            <template slot-scope="{ row }" slot="alarmTime">
              {{`${row.alarmBeginDate}至${row.alarmEndDate}`}}
            </template>
            <template slot-scope="{ row }" slot="governType">
              {{governCharacter.find(item => item.value == row.governType).label}}
            </template>
            <template slot-scope="{ row }" slot="governStatus">
              <span v-if="Number(row.governNo) <= 0 || Number(row.governStatus) <= 0" class="alarm-span">未处理</span>
              <span v-else-if="row.governNo == 1" class="alarm-span">{{appealStatus.find(item => item.value == row.governStatus).label}}</span>
              <span v-else-if="row.governNo == 2" class="alarm-span">{{governStatus.find(item => item.value == row.governStatus).label}}</span>
            </template>
            <template slot-scope="{ row }" slot="governId">
              {{row.governNo ? row.governId : '--'}}
            </template>
            <template slot-scope="{ row }" slot="alarmNum">
              <template v-if="row.noticeCode">
                <span class="alarm-span">已通报</span>
              </template>
              <template v-else>
                <span v-if="row.alarmNum >= 5" class="alarm-span">确认通报</span>
                <span v-else-if="row.alarmNum == 4" class="alarm-span"><span class="red-sapn">高</span>(未通报)</span>
                <span v-else-if="row.alarmNum == 3" class="alarm-span"><span class="org-span">中</span>(未通报)</span>
                <span v-else-if="row.alarmNum < 3 && row.alarmNum > 0" class="alarm-span"><span class="blue-span">低</span>(未通报)</span>
                <span v-else-if="row.alarmNum <= 0" class="alarm-span"><span class="grey-span">无</span>(未通报)</span>
              </template>
            </template>
            <template slot-scope="{ row }" slot="routeHop">
              <span v-if="Number(row.remainingVehicle) > 0" class="btn-span">{{ row.remainingVehicle }}</span>
              <span v-else>{{ row.remainingVehicle }}</span>
            </template>
            <template slot-scope="{ row }" slot="action">
              <span v-if="btnPrivilegeCode.illegal.includes('Details')" @click="openDetail(row)" class="btn-span">查看</span>
              <span v-if="filterAction(row, 'appeal') && btnPrivilegeCode.complaint.includes('Checked')" @click="complaint(row)" class="btn-span">申诉</span>
              <span v-if="filterAction(row, 'govern') && btnPrivilegeCode.governance.includes('Govern')" @click="govern(row)" class="btn-span">治理</span>
            </template>
          </Table>
          <div class="pagenation-wrapper">
            <Page
              :current="paginationConfig.currentPage"
              :page-size="paginationConfig.pageSize"
              :total="paginationConfig.total"
              @on-change="currentPageChange"
              show-total
              show-elevator
              size="small"/>
          </div>
        </div>
      </section>
    </div>
    <div v-else-if="componentName == 'IllegalDetail'" class="notification-detail">
      <IllegalDetail :params="{targetRow: clickRow, backTitle: '违章概况', backComponentName: 'default'}" @goBack="changeComponentName"></IllegalDetail>
    </div>
    <!-- 申诉/治理详情 -->
    <div v-else-if="componentName == 'ComplaintDetail'">
      <ComplaintDetail :params="{targetRow: clickRow, backTitle: '违章概况', backComponentName: 'default'}" :isShowPrevStepBtn="isShowPrevStepBtn" @goBack="changeComponentName"></ComplaintDetail>
    </div>
    <div v-else-if="componentName == 'GovernDetail'">
      <GovernDetail v-if="componentName == 'GovernDetail'" :params="{targetRow: clickRow, backTitle: '违章概况', backComponentName: 'default'}" @goBack="changeComponentName" @reSetGoverns="reSetGoverns"></GovernDetail>
    </div>
    <!-- 编辑申诉单 -->
    <div v-else-if="componentName == 'ComplaintEdit'">
      <ComplaintEdit v-if="componentName == 'ComplaintEdit'" :params="{targetRow: clickRow, backTitle: '违章概况', backComponentName: 'default'}" :isShowPrevStepBtn="false" @goBack="changeComponentName"></ComplaintEdit>
    </div>
    <!-- 添加治理单 -->
    <div v-else-if="componentName == 'IllegalCreate'">
      <IllegalCreate v-if="componentName == 'IllegalCreate'" :params="{...targetGovernParams, backTitle: '违章概况', backComponentName: 'default'}" :isShowPrevStepBtn="isShowPrevStepBtn" @goBack="changeComponentName"></IllegalCreate>
    </div>
    <!-- 编辑治理单 -->
    <div v-else-if="componentName == 'GovernEdit'">
      <GovernEdit v-if="clickRow && componentName == 'GovernEdit'" :params="{governId: clickRow.governId, backTitle: '违章概况', backComponentName: 'default'}"  @goBack="changeComponentName" @reSetGoverns="reSetGoverns"></GovernEdit>
    </div>
  </div>
</template>
<script>
import {btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, alarmTableMixin, pageChangeMixin, userInfoMixin} from '@/assets/js/mixin.js'
import { getTimeStrByType } from '@/assets/js/common.js'
import RadioText from '@/components/radio-text/index.vue'
import IllegalDetail from './illegal_detail/index.vue'
import IllegalCreate from './illegal_create/index.vue'
import GovernEdit from '../governance/govern_edit/index.vue'
import GovernDetail from '../governance/govern_detail/index.vue'
import ComplaintEdit from '../complaint/complaint_edit/index.vue'
import ComplaintDetail from '../complaint/complaint_detail/index.vue'
import axios from 'axios'
export default {
  mixins: [btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, alarmTableMixin, pageChangeMixin, userInfoMixin],
  name: 'illegal',
  components: {
    RadioText,
    IllegalDetail,
    IllegalCreate,
    GovernEdit,
    GovernDetail,
    ComplaintEdit,
    ComplaintDetail
  },
  data () {
    return {
      searchForm: {
        unitName: '',
        unitId: '',
        noticeId: '',
        time: null,
        alarmType: [0],
        status: [0],
        notificateTime: '',
        vehicleNo: ''
      },
      tableColmuns: [
        {type: 'index', title: '序号', width: 60, align: 'center'},
        {title: '企业名称', key: 'unitName', align: 'center'},
        {title: '违章车辆', slot: 'vehicle', align: 'center'},
        {title: '违章类型', key: 'alarmTypeRe', align: 'center'},
        // {title: '治理性质', slot: 'governType', align: 'center'},
        {title: '违章时间', slot: 'alarmTime', align: 'center'},
        {title: '持续时间', key: 'continueTimeRe', align: 'center'},
        // {title: '车辆被通报风险', slot: 'alarmNum', align: 'center'},
        {title: '申诉/治理单号', slot: 'governId', align: 'center'},
        {title: '申诉/治理状态', slot: 'governStatus', align: 'center'},
        {title: '操作', slot: 'action', align: 'center'}
      ],
      searchLoading: false,
      tableData: [],
      clickRow: null,
      isShowPrevStepBtn: true, // 是否在下级展示返回上一步按钮
      targetGovernParams: {
        noticeId: '',
        step: 1,
        beginTime: '',
        endTime: '',
        governWay: 2,
        vehicle: ''
      },
      // 申诉
      complaintData: null,
    } 
  },
  computed: {
    idStatusData() {
      return [
        {label: '全部', value: 0},
        {label: '未处理', value: -1},
        ...this.governStatus.map(item => {
          return {
            label: item.label,
            value: `${item.type}-${item.value}`
          }
        }),
        ...this.appealStatus.map(item => {
          return {
            label: item.label,
            value: `${item.type}-${item.value}`
          }
        }).slice(0, -1)
      ]
    }
  },
  created() {
    this.setDefaultTime()
  },
  methods: {
    // 设置默认时间
    setDefaultTime() {
      this.searchForm.time = [getTimeStrByType(new Date(), 4) + '-01', getTimeStrByType(new Date(), 3)]
    },
    async getTotalData (type) {
      if (!this.searchForm.time) {
        this.$Message.warning('请选择周期时间')
        return
      }
      if (type == 'search') {
        this.paginationConfig.currentPage = 1
      }
      // 治理方式
      let _governWay = []
      // 状态
      let _status = this.searchForm.status.map(item => {
        if (item == 0 || item == -1) {
          // _governWay = [1, 2]
          // return item
          return {
            type: item,
            value: item
          }
        } else {
          let _type = item.split('-')[0]
          let _value = item.split('-')[1]
          if (!_governWay.includes(_type)) {
            _governWay.push(_value)
          }
          return {
            type: _type,
            value: _value
          }
        }
      })
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.searchForm.unitId ? this.searchForm.unitId : 0,
        governWay: Number(_status[0].type),
        status: Number(_status[0].value),
        alarmType: this.searchForm.alarmType[0],
        keyword: this.searchForm.vehicleNo,
        vehicleNo: this.searchForm.vehicleNo,
        governCharacter: 0,
        // noticeId: this.searchForm.noticeId,
        beginTime: getTimeStrByType(this.searchForm.time[0], 3),
        endTime: getTimeStrByType(this.searchForm.time[1], 3),
        pageIndex: this.paginationConfig.currentPage,
        pageSize: this.paginationConfig.pageSize,
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/surveybreakrule/pageList`,
          data: _params
        })
        this.searchLoading = false
        this.isSearched = true
        if (data.code === 200) {
          this.tableData = data.data.records
          this.paginationConfig.total = data.data.total
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取违章信息失败, 请稍后重试!')
        }
      }
    },
    // 添加申诉或治理
    addComplaintOrGovern () {
      this.targetGovernParams.noticeId = '' // 新建治理 noticeId 为空
      this.targetGovernParams.beginTime = ''
      this.targetGovernParams.endTime = ''
      this.targetGovernParams.step = 0
      this.targetGovernParams.vehicle = ''
      this.targetGovernParams.governWay = 1
      this.isShowPrevStepBtn = true
      this.componentName = 'IllegalCreate'
    },
    // 根据条件展示操作btn
    filterAction (row, type) {
      if (row.hasNotice) {
        // 如果noticeCode存在 则不显示申诉
        if (type == 'appeal') {
          return false
        }
      }
      if (!row.governNo || !row.governStatus) {
        // 单号不存在 直接返回true
        return true
      }
      let result = false
      // appeal 申诉
      if (type == 'appeal' && row.governNo == 1) {
        if (row.governStatus == 1 || row.governStatus == 3) {
          // 申诉未提交 申述未通过
          result = true
        }
        if (row.hasNotice) {
          // 如果noticeCode存在 则不显示申诉
          result = false
        }
      } else if (type == 'govern' && row.governNo == 1 && (row.governStatus == 4||row.governStatus == 3)) {
        // 申诉已失效 显示治理按钮
        result = true
      } else if (type == 'govern' && row.governNo == 2) {
        // 治理
        // 治理中 治理待审核 治理未提交 治理未通过 都显示治理按钮
        if (row.governStatus == 3 || row.governStatus == 4 || row.governStatus == 5 || row.governStatus == 7) {
          result = true
        }
      }
      return result
    },
    // 查看通报详情
    openDetail (row) {
      row['appealId'] = row.governId
      // row['noticeId'] = this.searchForm.noticeId
      this.clickRow = row
      if (row.governId) {
        // 有单号 则跳转到对应的申述/治理详情页面
        if (row.governNo == 1) {
          this.componentName = 'ComplaintDetail'
        } else if (row.governNo == 2) {
          this.componentName = 'GovernDetail'
        }
      } else {
        // 如果没有单号则跳转到违章详情页
        this.componentName = 'IllegalDetail'     
      }
    },

    // 申诉
    complaint (row) {
      this.isShowPrevStepBtn = false
      row['appealId'] = row.governId
      if (this.searchForm.time) {
        // 如果查询时间存在 则将查询开始结束时间传过去
        row['beginTime'] = getTimeStrByType(this.searchForm.time[0], 3)
        row['endTime'] = getTimeStrByType(this.searchForm.time[1], 3)
      } else {
        // 没有 则从当前月1号至今
        row['beginTime'] = getTimeStrByType(new Date(), 4) + '-01'
        row['endTime'] = getTimeStrByType(new Date(), 3)
      }
      this.clickRow = row
      this.componentName = 'ComplaintEdit'
    },
    // 治理
    govern (row) {
      this.isShowPrevStepBtn = false
      this.clickRow = row
      if (this.searchForm.time) {
        // 如果查询时间存在 则将查询开始结束时间传过去
        row['beginTime'] = getTimeStrByType(this.searchForm.time[0], 3)
        row['endTime'] = getTimeStrByType(this.searchForm.time[1], 3)
      } else {
        // 没有 则从当前月1号至今
        row['beginTime'] = getTimeStrByType(new Date(), 4) + '-01'
        row['endTime'] = getTimeStrByType(new Date(), 3)
      }
      if (row.governStatus <= 0 || row.governNo <= 0||(row.governNo==1&&row.governStatus==3)) {
        // 治理单未生成 则跳转到违章生成页面
        this.targetGovernParams.noticeId = this.searchForm.noticeId
        this.targetGovernParams.beginTime = row.beginTime
        this.targetGovernParams.endTime = row.endTime
        this.targetGovernParams.step = 1
        this.targetGovernParams.governWay = 2
        this.targetGovernParams.vehicle = `${row.vehicleNo}-${row.vehiclePlateColor}`
        this.componentName = 'IllegalCreate'
      } else {
        // 直接打开治理编辑页面
        this.componentName = 'GovernEdit'
      }
    },
 
    // 违章类型改变
    alarmTypeChange (target) {
      this.searchForm.alarmType = target
    },
    // id状态改变
    idStatusChange (target) {
      this.searchForm.status = target
    },

    // 重开罚单
    reSetGoverns (params) {
      this.targetGovernParams.step = 1
      this.targetGovernParams.beginTime = params.beginTime
      this.targetGovernParams.endTime = params.endTime
      this.targetGovernParams.vehicle = params.vehicle
      this.isShowPrevStepBtn = false
      this.componentName = 'IllegalCreate'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-table-page();
.notification {
  .page-content {
    .search-wrapper {
      padding-left: 8px;
      .search-content {
        .search-item {
          margin: 10px 0;
          .form-btn {
            margin-right: 5px;
          }
        }
      }
    }
    .table-wrapper {
      .red-sapn {
        color: #ed4014
      }
      .org-span {
        color: #ff9900
      }
      .grey-span {
        color: #c5c8ce
      }
      .btn-span {
        margin-right: 5px;
      }
    }
  }
}
</style>