<template>
  <div class="notification-detail common-page">
    <div v-if="componentName == 'default'">
      <header class="page-header">
        <div @click="goBack" class="header-left">
          <Icon class="back-icon" type="md-arrow-round-back" />
          <span class="back-title">返回{{params.backTitle}}</span>
        </div>
        <div class="header-title">违章单详情</div>
      </header>
      <section v-if="alarmData" class="page-content">
        <Spin size="large" fix v-if="searchLoading"></Spin>
        <div class="content-header">
          <h2 class="content-title">违章车辆:<span class="content-value">{{`${alarmData.vehicleNo}(${alarmData.vehiclePlateColor})`}}</span></h2>
          <h2 class="content-sub-title">所属企业:<span class="content-value">{{ alarmData.unitName}}</span></h2>
          <!-- <h2 class="content-sub-title">通报周期:<span class="content-value">{{`${alarmData.noticeBeginDate} 至 ${alarmData.noticeEndDate}`}}</span></h2> -->
          <!-- <h2 class="content-sub-title">
            被通报风险:
            <span v-if="alarmData.alarmNum >= 5" class="content-value red-sapn">已通报</span>
            <span v-else-if="alarmData.alarmNum == 4" class="content-value red-sapn">高</span>
            <span v-else-if="alarmData.alarmNum == 3" class="content-value org-span">中</span>
            <span v-else-if="alarmData.alarmNum < 3 && alarmData.alarmNum > 0" class="content-value blue-span">低</span>
            <span v-else-if="alarmData.alarmNum <= 0" class="content-value grey-span">无</span>
          </h2> -->
        </div>
        <div class="govern-content">
          <div v-for="(item, index) in alarmData.breakRulesContents" :key="index" class="detail-item">
            <header class="item-title">违章类型: {{alarmType.find(alarmItem => alarmItem.value == item.alarmType).label}}</header>
            <div class="govern-notice">
              <!-- <div class="notice-item">
                <span class="notice-label">归属:</span><span class="notice-value">{{item.governId}}</span>
              </div> -->
              <div class="notice-item">
                <span class="notice-label">违章条数:</span><span class="notice-value">{{item.alarms.length}}</span>
              </div>
              <!-- <div class="notice-item">
                <span class="notice-label">所属治理性质:</span><span class="notice-value">{{governCharacter.find(gItem => gItem.value == item.governType).label}}</span>
              </div> -->
              <div class="notice-item">
                <span class="notice-label">状态:</span><span class="notice-value status-value">{{getStatusByGovernType(item.governWay, item.status)}}</span>
                <span v-if="item.status > 0 && btnPrivilegeCode.illegal.includes('Details')" @click="openDetail(item)" class="btn-span">查看</span>
              </div>
            </div>
            <div class="item-table">
              <Table :columns="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).colmuns" :data="item.alarms" size="small" max-height="300">
              </Table>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- 申诉/治理详情 -->
    <div v-else-if="componentName == 'ComplaintDetail'">
      <ComplaintDetail :params="{targetRow: clickRow, backTitle: '违章单详情', backComponentName: 'default'}" :isShowPrevStepBtn="false" @goBack="changeComponentName"></ComplaintDetail>
    </div>
    <div v-else-if="componentName == 'GovernDetail'">
      <GovernDetail v-if="componentName == 'GovernDetail'" :params="{targetRow: clickRow, backTitle: '违章单详情', backComponentName: 'default'}" @goBack="changeComponentName" @reSetGoverns="reSetGoverns"></GovernDetail>
    </div>
    <!-- 播放视频 -->
    <Modal :footer-hide="true" width="772" v-model="videoModal" :title="videoForm.title">
      <video v-if="isVideo" width="740" height="500" autoplay controls>
        <source :src="videoForm.url" type="video/mp4" />
        您的浏览器不支持 video 标签。
      </video>
      <img v-else style="width: 740px; height: 500px; object-fit: contain;" :src="videoForm.url">
    </Modal>
  </div>
</template>
<script>
import axios from 'axios'
import {btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, userInfoMixin, dangerousDrivingBehavior} from '@/assets/js/mixin.js'
import ComplaintDetail from '../../complaint/complaint_detail/index.vue'
import GovernDetail from '../../governance/govern_detail/index.vue'
export default {
  mixins: [btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, userInfoMixin, dangerousDrivingBehavior],
  components: {
    ComplaintDetail,
    GovernDetail
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          targetRow: null,
          beginTime: '',
          endTime: '',
          backTitle: '',
          backComponentName: ''
        }
      }
    }
  },
  data () {
    return {
      componentName: 'default',
      alarmData: null,
      clickRow: null,
    }
  },
  created () {
    this.getAlarmDetailData()
  },
  methods: {
    // 获取违章信息详情页面
    async getAlarmDetailData () {
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.userInfo.unitId,
        // noticeId: this.from == 'govern' ? this.params.targetRow.noticeCode : this.params.targetRow.noticeId,
        beginTime: this.params.targetRow.beginTime,
        endTime: this.params.targetRow.endTime,
        vehicleNo: this.params.targetRow.vehicleNo,
        vehiclePlateColor: this.params.targetRow.vehiclePlateColor
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/surveybreakrule/breakRulesDetails`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.alarmData = data.data
          // this.alarmTableColmuns.forEach(item => {
          //   this.alarmData.breakRulesContents.forEach(aItem => {
          //     if (item.id == aItem.alarmType) {
          //       item.data = aItem.alarms
          //     }
          //   })
          // })
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取违章详情失败, 请稍后重试!')
        }
      }
    },
    // 查看违章详情
    openDetail (item) {
      this.clickRow = item
      this.clickRow['vehicleNo'] = item.vehicleNo ? item.vehicleNo : this.alarmData.vehicleNo
      this.clickRow['vehiclePlateColor'] = item.vehiclePlateColor ? item.vehiclePlateColor : this.alarmData.vehiclePlateColor
      this.clickRow['appealId'] = item.governId
      this.clickRow['beginTime'] = item.beginDate ? item.beginDate : this.alarmData.noticeBeginDate
      this.clickRow['endTime'] = item.endDate ? item.endDate : this.alarmData.noticeEndDate
      if (this.clickRow.governWay == 1) {
        this.componentName = 'ComplaintDetail'
      } else if (this.clickRow.governWay == 2) {
        this.componentName = 'GovernDetail'
      }
    },
    // 子页面跳转会父页面
    changeComponentName (backComponentName) {
      this.componentName = backComponentName
      if (backComponentName == 'default') {
        this.getAlarmDetailData()
      }
    },
    // 重开罚单
    reSetGoverns (params) {
      this.targetGovernParams.step = 1
      this.targetGovernParams.beginTime = params.beginTime
      this.targetGovernParams.endTime = params.endTime
      this.targetGovernParams.vehicle = params.vehicle
      this.componentName = 'IllegalCreate'
    },
    goBack () {
      this.$emit('goBack', this.params.backComponentName)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.notification-detail {
  position: relative;
  height: 100%;
  width: 100%;
  .page-content {
    .content-value {
      margin-left: 20px;
    }
    .content-sub-title {
      .red-sapn {
        color: #ed4014
      }
      .org-span {
        color: #ff9900
      }
      .grey-span {
        color: #c5c8ce
      }
    }
    .govern-content {
      .notice-item {
        .btn-span {
          margin-left: 20px;
        }
        .status-value {
          max-width: calc(100% - 200px) !important;
        }
      }
    }
  }
}
</style>