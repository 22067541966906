<template>
  <div class="govern-detail common-page">
    <Spin size="large" fix v-if="searchLoading"></Spin>
    <div v-if="componentName == 'default'">
      <header class="page-header">
        <div @click="goBack" class="header-left">
          <Icon class="back-icon" type="md-arrow-round-back" />
          <span class="back-title">返回{{params.backTitle}}</span>
        </div>
        <div class="header-title">治理单详情</div>
      </header>
      <section v-if="alarmData" class="page-content">
        <div class="content-progress">
          <Steps :current="currentStep - 3" :status="stepStatus" size="small">
            <Step v-for="item in tempGovernStatus" :title="item.label" :key="item.value"></Step>
          </Steps>
        </div>
        <!-- <div class="content-header">
          <h1 class="content-title">通报标题:  {{alarmData.noticeName}}</h1>
          <h2 class="content-sub-title">通报周期：  {{alarmData.noticePeriod}}</h2>
        </div> -->
        <div class="govern-content">
          <div class="govern-notice">
            <div class="notice-item">
              <span class="notice-label">治理车辆:</span><span class="notice-value">{{`${alarmData.vehicleNo}(${alarmData.vehiclePlateColor})`}}</span>
            </div>
            <div class="notice-item">
              <span class="notice-label">所属企业:</span><span class="notice-value">{{alarmData.unitName}}</span>
            </div>
            <div class="notice-item">
              <span class="notice-label">治理证据:</span>
              <span v-if="alarmData.governFiles.length == 0" class="notice-value">待上传</span>
              <div v-else class="notice-value" >
                <div class=" file-stys">
                  <div v-for="item in alarmData.governFiles" class="btn-span file-sty" :key="item.id">{{item.fileName}}&nbsp;<Button size="small" @click="previewFile(item)" type="primary">预览</Button>&nbsp;<Button @click="downLoadFile(item.url)" size="small" type="warning">下载</Button></div>
                </div>
              </div>
            </div>
            <div class="notice-item">
              <span class="notice-label">治理对象:</span>
              <span class="notice-value driver-value">{{alarmData.drivers.map(item => item.name).join(', ')}}</span>
            </div>
            <div class="notice-item">
              <span class="notice-label">治理罚单:</span>
              <span class="notice-value">
                <!-- <span class="btn-span">下载罚单</span> -->
                <span class="btn-span" @click="previewNotice">在线预览</span>
                <span v-if="alarmData.status != 8 && alarmData.status != 6 && btnPrivilegeCode.governance.includes('ResetTicket')" class="btn-span" @click="checkSetGovern">重开罚单</span>
              </span>
            </div>
            <div class="notice-item">
              <span class="notice-label">治理单号:</span><span class="notice-value">{{alarmData.governId}}</span>
            </div>
            <div class="notice-item">
              <span class="notice-label">治理单状态:</span><span class="notice-value">{{alarmData.governStatus}}</span>
            </div>
            <div v-if="alarmData.status != 8 && alarmData.status != 6" class="btn-wrapper">
              <Button v-if="btnPrivilegeCode.governance.includes('update')" @click="componentName = 'GovernEdit'" class="option-btn" type="primary" icon="ios-create" size="small">编辑</Button>
              <Button v-if="btnPrivilegeCode.governance.includes('Revoke')" @click="repeal" :loading="searchLoading" class="option-btn" type="warning" icon="ios-undo" size="small">撤销</Button>
            </div>
          </div>      
          <div class="govern-content">
            <template v-for="item in alarmData.contents">
              <div v-if="item.alarms.length > 0" :key="item.alarmType" class="detail-item">
                <header class="item-title">违章类型: {{alarmType.find(alarmItem => alarmItem.value == item.alarmType).label}}</header>
                <div class="govern-notice">
                  <div class="notice-item">
                    <span class="notice-label">所属治理性质:</span><span class="notice-value">{{governCharacter.find(gItem => gItem.value == item.governCharacter).label}}</span>
                  </div>
                  <div class="notice-item">
                    <span class="notice-label">违章条数:</span><span class="notice-value">{{item.alarms.length}}</span>
                  </div>
                  <div class="notice-item">
                    <span class="notice-label">治理方式:</span><span class="notice-value">{{getPunishByCode(item.governType.punishIds).join(',')}}</span>
                  </div>
                  <div class="notice-item">
                    <span class="notice-label">治理备注:</span><span class="notice-value">{{item.governType.remark}}</span>
                  </div>
                </div>
                <div class="item-table">
                  <Table :columns="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).colmuns" :data="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).data" size="small" max-height="300">
                  </Table>
                </div>
              </div>
            </template>
          </div>
          <div class="govern-notice">
            <div class="notice-item">
              <span class="notice-label">审核意见:</span><span class="notice-value">{{alarmData.auditView}}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- 编辑治理单 -->
    <div v-else-if="componentName == 'GovernEdit'">
      <GovernEdit v-if="alarmData && componentName == 'GovernEdit'" :params="{governId: alarmData.governId, backTitle: '治理详情', backComponentName: 'default'}"  @goBack="goBackForGovernDetail" @reSetGoverns="reSetGoverns"></GovernEdit>
    </div>

    <!-- 预览罚单 -->
    <Modal v-model="ticketModal" title="预览罚单" :footer-hide="true" class="common-modal" width="80">
      <div v-if="ticketModal" class="ticket-wrapper">
        <IllegalTicketTemplate :alarmData="alarmData" :driversName="driversName" title="违章告知书(公司留底)"></IllegalTicketTemplate>
        <Divider :dashed="true"/>
        <IllegalTicketTemplate :alarmData="alarmData" :driversName="driversName" title="违章告知书(当事人)"></IllegalTicketTemplate>
      </div>
    </Modal>
    <!-- 播放视频 -->
    <Modal :footer-hide="true" width="772" v-model="videoModal" :title="videoForm.title">
      <video v-if="videoModal" width="740" height="500" autoplay controls>
        <source :src="videoForm.url" type="video/mp4" />
        您的浏览器不支持 video 标签。
      </video>
    </Modal>
  </div>
</template>
<script>
import IllegalTicketTemplate from '../../illegal/illegal_create/illegal_ticket_template/index.vue'
import GovernEdit from '../govern_edit/index.vue'
import axios from 'axios'
import { Base64 } from 'js-base64'
import {btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, dangerousDrivingBehavior} from '@/assets/js/mixin.js'
export default {
  mixins: [btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, dangerousDrivingBehavior],
  components: {
    GovernEdit, IllegalTicketTemplate
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          targetRow: null,
          backTitle: '',
          backComponentName: ''
        }
      }
    }
  },
  data () {
    return {
      componentName: 'default',
      currentStep: 3, // 默认治理中
      stepStatus: 'process',
      tempGovernStatus: [],
      searchLoading: false,
      alarmData: null,
      driversName: [],
      // 编辑
      ticketModal: false
    }
  },
  created () {
    this.getGovernDetailData()
  },
  methods: {
    // 获取详情数据
    async getGovernDetailData () {
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'get',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/governDetail/${this.params.targetRow.governId}`
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.alarmData = data.data
          this.initStep()
          this.initDriver()
          if (this.alarmData.status == 7) {
            // 治理未通过
            this.stepStatus = 'error'
            this.currentStep = 7
            this.tempGovernStatus.push({label: '治理未通过', value: 7, type: 2})
          } else if (this.alarmData.status == 8) {
            // 治理已成功
            this.stepStatus = 'process'
            this.currentStep = 7
            this.tempGovernStatus.push({label: '治理已通过', value: 7, type: 2})
          } else {
            this.stepStatus = 'process'
            this.currentStep = this.alarmData.status
            this.tempGovernStatus.push({label: this.alarmData.governStatus, value: 7, type: 2})
          }
          this.alarmTableColmuns.forEach(item => {
            this.alarmData.contents.forEach(aItem => {
              // 为每个违章对象添加编辑form表单
              let _form = {
                punishId: [],
                punishTime: '',
                runTime: '',
                stopTime: '',
                punishMoney: 0,
                remark: ''
              }
              if (item.id == aItem.alarmType) {
                item.data = aItem.alarms
                // 为每个违章对象添加编辑form表单
                if (aItem.governType) {
                  // 如果治理类型数据存在
                  _form = {
                    punishId: aItem.governType.punishIds,
                    punishTime: [aItem.governType.runTime, aItem.governType.stopTime],
                    runTime: aItem.governType.runTime,
                    stopTime: aItem.governType.stopTime,
                    punishMoney: aItem.governType.amount,
                    remark: aItem.governType.remark
                  }
                }
                this.$set(aItem, 'editForm', _form)
              }
            })
          })
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取治理单详情失败, 请稍后重试!')
        }
      }
    },
    // 初始化进度条
    initStep () {
      this.tempGovernStatus = this.governStatus.slice(1, -2)
    },
    // 初始化驾驶员对象
    initDriver () {
      if(this.alarmData) {
        this.driversName = this.alarmData.drivers.map(item => {
          return {
            key: item.id,
            label: `${item.name}-${item.dqId}-${item.tel}`,
            ...item
          }
        })
      }
    },
    // 在线预览
    previewNotice() {
      this.ticketModal = true
    },
    // 重开罚单
    checkSetGovern () {
      this.$Modal.confirm({
        title: '重开罚单',
        content: `重开罚单将对现有治理单内容重置,是否继续？`,
        onOk: async () => {
          this.resetGovern()
        },
        onCancel: () => {}
      })
    },
    // 预览文件
    previewFile(item) {
      let fileName =(Math.random() * 10000000).toString(16).substr(0, 4) + (new Date()).getTime()+item.fileName
      window.open(`http://kkfileview.dsf360.com/onlinePreview?url=`+encodeURIComponent(Base64.encode(item.url+'&fullfilename='+fileName)))
    },
    // 下载文件
    downLoadFile(url) {
      window.location.href=url
    },
    async resetGovern() {
      let _params = {
        appKey: this.alarmData.appKey,
        governId: this.alarmData.governId,
        beginTime: this.alarmData.governBeginTime,
        endTime: this.alarmData.governEndTime,
        // noticeId: this.alarmData.noticeId,
        unitId: this.alarmData.unitId,
        vehicleNo: this.alarmData.vehicleNo,
        vehiclePlateColor:this.alarmData.vehiclePlateColor
      }
      this.searchLoading = true
      try {
        let {data} =await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/resetGovern`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.$Message.success('重开罚单成功')
          let params = {
            step : 1,
            vehicle : `${_params.vehicleNo}-${_params.vehiclePlateColor}`,
            beginTime: this.alarmData.governBeginTime,
            endTime: this.alarmData.governEndTime,
          }
          this.reSetGoverns(params)
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('重开罚单失败, 请稍后重试!')
        }
      }
    },
    reSetGoverns(params) {
      this.$emit('reSetGoverns', params)
    },
    // 撤销
    repeal () {
      this.$Modal.confirm({
        title: '撤销',
        content: '撤销操作会删除治理单及附带罚单内容,是否继续？',
        onOk: async () => {
          this.searchLoading = true
          try {
            let {data} = await axios({
              method: 'get',
              url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/removeGovern/${this.params.targetRow.governId}`
            })
            this.searchLoading = false
            if (data.code === 200) {
              this.$Message.success('撤销成功')
              this.goBack()
            } else {
              this.$Message.warning(data.msg)
            }
          } catch (error) {
            this.searchLoading = false
            if (!this.checkPrivilege(error)) {
              this.$Message.error('撤销失败, 请稍后重试!')
            }
          }
        },
        onCancel: () => {}
      })
    },
    // 该组件内的跳转
    goBackForGovernDetail () {
      this.componentName = 'default'
      this.getGovernDetailData()
    },
    goBack () {
      this.$emit('goBack', this.params.backComponentName)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.govern-detail {
  position: relative;
  height: 100%;
  width: 100%;
  .govern-content {
    .btn-span {
      margin-right: 15px;
    }
    .file-stys {
      width:100%;
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
      .file-sty{
        width:500px;
      }
    }
  }
}
</style>